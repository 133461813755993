import { createHmac } from "crypto";

class AzureServiceBus {
  constructor(url, sas_key, policy_name = "dev8a8dash8a") {
    this._url = url;
    this._token = this._get_token(url, sas_key, policy_name);
  }

  async get_message() {
    let request = new XMLHttpRequest();
    let processing = true;
    request.open("DELETE", this._url + "/messages/head");
    request.onload = _ => {
      processing = false;
    };
    request.setRequestHeader(
      "Content-Type",
      "application/atom+xml;type=entry;charset=utf-8"
    );
    request.setRequestHeader("Authorization", this._token);
    request.send();

    while (processing) {
      await this._wait(10);
    }
    return request.response;
  }

  _wait(milliseconds = 1000) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve("resolved");
      }, milliseconds);
    });
  }

  _get_token(url, key, policy_name) {
    let encoded = encodeURIComponent(url);
    let now = new Date();
    let week = 60 * 60 * 24;
    let ttl = Math.round(now.getTime() / 1000) + week;
    let signature = encoded + "\n" + ttl;
    let signatureUTF8 = this._transform_to_utf8(signature);
    let hash = createHmac("sha256", key)
      .update(signatureUTF8)
      .digest("base64");
    return (
      "SharedAccessSignature sr=" +
      encoded +
      "&sig=" +
      encodeURIComponent(hash) +
      "&se=" +
      ttl +
      "&skn=" +
      policy_name
    );
  }

  _transform_to_utf8(signature) {
    return decodeURIComponent(escape(signature));
  }
}

export default AzureServiceBus;
